:root {
  --yellow: #ffc600;
  --gray: #2f4858;
  --brown-orange: #d77451;
  --brown-orange-hover: #c57451;
}

body {
  display: flex;
  margin: 0;
  padding: 0;
  background-image: linear-gradient(
    to right top,
    #0fff86,
    #54fb6e,
    #76f655,
    #91f139,
    #a8eb12
  );
  height: 100vh;
}

// main parent (the container of elements)
.cal {
  width: 300px;
  height: 400px;
  margin: auto;
  border: 10px solid var(--gray);
  background-color: #006b80;
  border-radius: 26px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

// // all components (childs of .cal)
// .cal__component {
// }

// the display part of the calculator
.cal__display {
  height: 40%;
  border-bottom: 3px solid var(--gray);
}

.cal__display-section {
  width: 100%;
  height: 50%;
  background-color: #008f92;
  color: white;
  font-size: 24px;
  font-family: 'Orbitron', sans-serif;
  display: flex;
  align-items: center;
}

.cal__display-input {
  padding-left: 12px;
}

.cal__display-result {
  direction: rtl;
}

// buttons div
.cal .btns {
  display: grid;
  grid-template-areas:
    'num num num fun fun'
    'num num num opr opr'
    'num num num opr opr'
    'num num num res res';
  grid-gap: 10px;
  height: 60%;
  margin: 25px 0;
}

// all buttons in the calculator
.cal .btn {
  font-family: 'Orbitron', sans-serif;
  cursor: pointer;
  border: 2px solid var(--gray);
  border-radius: 6px;
  transform: skew(3deg, 1deg);
  box-shadow: 2px 2px 0 var(--gray);
  width: 50px;
  height: 30px;
  transition: all 50ms ease;
  &:hover {
    background-color: #ddd;
  }
}

.cal .btn[data-number='0'] {
  grid-column: 2 span;
  width: 90%;
}

// when the button is clicked
.cal .btn:active {
  transform: translate(2px, 2px) skew(3deg, 1deg);
  box-shadow: 0px 0px 0 var(--gray);
}

.cal__fun .btn {
  color: white;
  background: var(--brown-orange);
  &:hover {
    background: var(--brown-orange-hover);
  }
}

.cal__numbers,
.cal__fun,
.cal__operation,
.cal__result {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 10px;
}

// the parent of the numbers
.cal__numbers {
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  grid-area: num;
}

.cal__fun {
  grid-area: fun;
}

.cal__operation {
  grid-area: opr;
}

.cal__result {
  grid-area: res;
}

// all buttons of numbers
